<template>
    <div v-if="row.item.credit" class="text-success">
        +{{ row.item.amount | priceFormat }} &euro;
    </div>
    <div v-else class="text-danger">
        -{{ row.item.amount | priceFormat }} &euro;
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
}
</script>